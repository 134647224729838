<template>
	<div class="page">
		<van-row class="pageIndex">
			<van-col><span class="current">{{current + 1}}</span>/ {{total}}</van-col>
		</van-row>
			
		<van-row class="main">
			<van-col class="qName">
				<van-cell>{{qName}}</van-cell>
			</van-col>
			<van-col span="24">
				<van-radio-group v-model="qCheck">
					<van-cell-group>
						<van-cell :title="item.letter + ': ' + item.name" clickable @click="changeAnswer(item.id)" v-for="item in qAnswers" :key="item.id">
							<template #right-icon>
								<van-radio :name="item.id" checked-color="#FB6B23" />
							</template>
						</van-cell>
					</van-cell-group>
				</van-radio-group>
			</van-col>
		</van-row>

		<van-row type="flex" justify="center" style="margin-top: 1rem;color: #FB6B23;">
			<van-col span="6" align="center" v-if="current > 0">
				<span @click="nextQuestion(current - 1)">{{translate('previous_question')}}</span>
			</van-col>
			<van-col span="6" align="center" v-if="(current + 1) < total">
				<span @click="nextQuestion(current + 1)">{{translate('next_question')}}</span>
			</van-col>
		</van-row>

		<van-row class="footerBtn" v-if="(current + 1) == total">
			<van-button color="#FB6B23" block @click="submitExam">{{translate('confirm_submission')}}</van-button>
		</van-row>

	</div>
</template>

<script>

	import { Dialog } from 'vant';

	export default {
		name: 'risk_exam',
		data() {
			return {

				token: "",

				current: 0,
				total: 0,

				questions: [],

				qName: "",
				qAnswers: [],
				qCheck: "",
			}
		},
		
		async mounted() {

			this.token = sessionStorage.getItem('token')

			let data = await this.getData()

			this.total = data.total
			
			for(let i in data.lists) {
				data.lists[i].checkAnswer = 0
			}
			console.log(data.lists)
			this.questions = data.lists

			this.nextQuestion(0)

		},
		methods: {

			

			async getData(){

				let _this = this

				return new Promise((resolve, reject) => {
					_this.$axios({
						method: 'post',
						url: 'fund_risk/questions',
						data: {
							token: this.token
						}
					}).then ((res) => {
						console.log(reject)
						if (res.success) {
							resolve(res.data)							
						} else {
							resolve(0)
						}
					})

				})
				
			},

			submitExam() {
				Dialog.confirm({
					message: this.translate('submit_a_risk_assessment'),
				})
				.then(() => {
					// on confirm
					this.$axios({
						method: 'post',
						url: 'fund_risk/exam',
						data: {
							token: this.token,
							questions: JSON.stringify(this.questions)
						}
					}).then ((res) => {
						if (res.success) {	
							
							this.$router.push('/risk/level?id=' + res.data.risk_id) 

						} else {
							this.$toast.fail(res.error_text)
						}
					})
				})
				.catch(() => {
					// on cancel
				});
			},

			nextQuestion(current) {

				let questions = this.questions

				for(let i in questions) {
					if(i == current) {
						this.qName = questions[i].name
						this.qAnswers = questions[i].answers
						this.qCheck = questions[i].checkAnswer
					}
				}

				this.current = current
			},

			changeAnswer(answer) {
				
				let current = this.current
				let questions = this.questions

				for(let i in questions[current].answers) {
					if(questions[current].answers[i].id == answer) {
						questions[current].checkAnswer = answer

						break
					}
				}

				this.questions = questions
				this.qCheck = answer
			}
		}
	}
</script>

<style scoped>

.page {
	background-color: #EFEFEF;
	padding: 1rem;
}
.pageIndex {
	height: 3rem;
	line-height: 3rem;
	margin-bottom: 1rem;
	font-size: 1.2rem;
	color: #6C6C6C;
	padding-left: 5px;
}
.pageIndex .current {
	font-size: 2rem;
	color: #000000;
}
.main {
	width: 100%;
	min-height: 8rem;
	overflow-y: scroll;
	background-color: #FFFFFF;
	border-radius: .5rem;
	box-sizing: border-box;
}
.qName {
	font-size: 1.3rem;
	color: #110601;
	line-height: 150%;
	font-weight: bold;
}
.footerBtn {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 1rem;
	box-sizing: border-box;
}
</style>